<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0" >
        <span @click="$router.go(-1)" >
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Detalles departamento
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editDepartment">
        <b-form
            @submit.prevent="handleSubmit"
            @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6" >
              <validation-provider
                  #default="{ errors }"
                  :name="'Nombre'"
                  rules="required"
              >
                <b-form-group
                    :label="'Nombre'"
                    label-for="department-name"
                >
                  <b-form-input
                      v-model="department.name"
                      name="name"
                      :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="6" >
              <validation-provider
                  #default="{ errors }"
                  :name="'Alias'"
                  rules="required"
              >
                <b-form-group
                    :label="'Alias'"
                    label-for="department-sku"
                >
                  <b-form-input
                      v-model="department.SKU"
                      name="Alias"
                      :placeholder="'Alias'"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" >
              <b-form-group
                  :label="'Descripción'"
                  label-for="department-description"
              >
                <quill-editor
                    v-model="department.description"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" >
              <b-form-checkbox
                  v-model="department.active"
                  value="1"
              >
                Activo
              </b-form-checkbox>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                  type="submit"
                  variant="primary"
                  class="mt-2 mr-1"
              >
                {{ 'Actualizar' }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      department: 'departments/getDepartment',
      currentClient: 'clients/getCurrentClient',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'departments/edit',
      getDepartment: 'departments/getDepartment',
    }),
    handleSubmit() {
      this.$refs.editDepartment.validate().then(success => {
        if (success) {
          const { id } = this.department
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, department: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.department.name)
      data.append('SKU', this.department.SKU)
      data.append('description', this.department.description)
      data.append('active', this.department.active)

      return data
    },
  },
  async created() {
    await this.getDepartment(this.$route.params.id)
  },
}
</script>

<style lang="scss">
</style>
